import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Cashfree = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const orderId = searchParams.get("orderId");
  const amount = parseFloat(searchParams.get("amount"));

  const initializeSDK = async () => {
    try {
      return await load({
        mode: "production",
      });
    } catch (error) {
      console.error("Failed to initialize Cashfree SDK:", error);
      throw error;
    }
  };

  const createOrder = async () => {
    const finalOrderId = orderId || Math.random().toString(36).substring(2, 15);

    try {
      const response = await axios.post(
        "https://api.kreditbeeapp.site/cashfree",
        {
          orderId: finalOrderId,
          amount: amount,
        }
      );

      if (!response.data || !response.data.cf_order_id) {
        throw new Error("Invalid response from server");
      }

      return response.data;
    } catch (error) {
      console.error("Error creating order:", error);
      throw new Error(
        error.response?.data?.message || "Failed to create order"
      );
    }
  };

  const doPayment = async (cashfree, paymentSessionId) => {
    try {
      const checkoutOptions = {
        paymentSessionId: paymentSessionId,
        redirectTarget: "_self",
        onSuccess: (data) => {
          console.log("Payment success:", data);
        },
        onFailure: (data) => {
          console.log("Payment failed:", data);
          setError("Payment failed. Please try again.");
        },
        onClose: () => {
          console.log("Payment window closed");
        },
      };

      await cashfree.checkout(checkoutOptions);
    } catch (error) {
      console.error("Payment processing failed:", error);
      throw error;
    }
  };

  useEffect(() => {
    const initializePayment = async () => {
      if (!orderId) {
        setError("Order ID is required");
        setLoading(false);
        return;
      }

      if (!amount || isNaN(amount) || amount <= 0) {
        setError("Valid amount is required");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const cashfree = await initializeSDK();
        const orderData = await createOrder();

        if (orderData && orderData.payment_session_id) {
          await doPayment(cashfree, orderData.payment_session_id);
        } else {
          throw new Error("Invalid order data received");
        }
      } catch (error) {
        console.error("Payment initialization failed:", error);
        setError(error.message || "Payment initialization failed");
      } finally {
        setLoading(false);
      }
    };

    initializePayment();
  }, [orderId, amount]);

  if (error) {
    return <div className="text-red-600 p-4">Error: {error}</div>;
  }

  return (
    <div className="p-4">
      {loading ? (
        <div>Processing payment...</div>
      ) : (
        <div>Payment completed</div>
      )}
    </div>
  );
};

export default Cashfree;
