import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const generateRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Home = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null); // State to hold error messages

  const phonePay = async (data) => {
    try {
      const response = await axios.post(
        "https://api.kreditbeeapp.site/phonepay",
        data
      );

      if (response.status === 200) {
        return [true, response.data];
      }

      return [false, response.data];
    } catch (error) {
      console.error("Error during phonePay:", error);
      setError(
        error.response?.data?.message || "An error occurred during payment."
      );
      return [false, null];
    }
  };

  const payHandler = async () => {
    setLoading(true);
    const [success, response] = await phonePay({
      amount: +amount * 100,

      deviceContext: {
        deviceOS: "ANDROID",
      },
      merchantId: "M22I8787FQRGZ",

      redirectUrl: `https://kreditbeeapp.site/sign-up/payment-method/pay/success`,
      redirectMode: "GET",
      merchantTransactionId: generateRandomString(10).toUpperCase(),
      merchantUserId: generateRandomString(10).toUpperCase(),
      mobileNumber: generateRandomString(10).toUpperCase(),
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    });

    console.log(success);

    if (success) {
      window.location.replace(
        response?.data?.data?.instrumentResponse?.redirectInfo?.url
      );
    }
    setLoading(false);
  };

  const amount = searchParams.get("amount");

  useEffect(() => {
    if (amount) {
      payHandler();
    }
  }, [amount]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-600">{error}</div>
      ) : (
        <div>{amount}</div>
      )}
    </div>
  );
};

export default Home;
